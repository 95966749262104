
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from '@/components/GiveawayDetailCard.vue';
@Component({
  components: {
    Card
  }
})
export default class GiveawayDetail extends Vue {
  @Prop({default: true}) active!: boolean;
  @Prop({default: false}) buttonDisabled!: boolean;
  @Prop() text!: string;
  @Prop({default: 'مشاهده جزئیات'}) buttonText!: string;
  private img = require("@/assets/images/instagram.png");
  private main_img = require("@/assets/images/picture.png");

  handler (e: Event): void {
    e.preventDefault();
    this.$router.push({name: 'rolling-giveaway'})
  }
}
