
import { Component, Vue } from 'vue-property-decorator';
import GoToArchive from '../components/GoToArchive.vue'
import GoToNewGiveaway from '../components/GoToNewGiveaway.vue'
import GiveawayDetail from '../components/GiveawayDetail.vue'
import PostDetail from '../components/PostDetail.vue'
import { GetGiveawayResponse } from '@/plugins/api';
@Component({
  components: {
      GoToArchive,
      GoToNewGiveaway,
      GiveawayDetail,
      PostDetail
  },
})
export default class GiveawayPage extends Vue {

    private post_text = 'در حال بارگزاری...';
    private loading = false;
    private url = process.env.VUE_APP_BASE_LINK;

    private giveaway: GetGiveawayResponse|null = null;

    async getGiveaway (): Promise<void>{
        this.loading = true;
        const give_id = this.$route.params.give_id;
        const resolved = this.$router.resolve({name: 'rolling-giveaway'})
        this.url = `${process.env.VUE_APP_BASE_LINK}${resolved.href}`
        try {
            const res = await this.$api.getGiveaway(give_id);
            this.giveaway = res.data;
            this.post_text = `تعداد دنبال کنندگان:   ${this.giveaway.post?.follower_count||'بررسی نشده'}\nتعداد لایک:  ${this.giveaway.post?.likes_count||'بررسی نشده'}\nتعداد کامنت ها:  ${this.giveaway.post?.comments_count||'بررسی نشده'}`;
        } catch (err) {
            console.log(err);
        }
        this.loading = false;
    }

    async onStartClicked (e: Event): Promise<void> {
        e.preventDefault()
    }

    unsubscribe (): void{
        this.$socket.$unsubscribe('give-so-close');
        this.$socket.$unsubscribe('give-crawled');
    }

    giveIsSoClose (payload: any) {
        if (payload.model_id == this.giveaway?.model_id) {
            this.$toast.success('قرعه کشی به زودی آماده می شود.')
            this.unsubscribe()
            this.$router.push({name: 'rolling-giveaway'});
        }
    }

    giveIsReady (payload: any) {
        if (payload.model_id == this.giveaway?.model_id) {
            this.$toast.success('قرعه کشی آماده شروع است.')
            this.unsubscribe()
            this.$router.push({name: 'rolling-giveaway'});
        }
    }

    giveIsCrawled (payload: any) {
        if (this.giveaway?.post && payload.crawl && payload.model_id == this.giveaway?.model_id && typeof payload.crawl !== 'undefined') {
            this.$toast.success('داده های قرعه‌کشی بروز شدند.')
            this.giveaway.crawled_at = Math.ceil(payload.crawled_at.getTime() * 0.001);
            this.giveaway.post.likes_count = payload.crawl.post.likes_count;
            this.giveaway.post.comments_count = payload.crawl.post.comments_count;
            this.giveaway.post.follower_count = payload.crawl.post.follower_count;
        }
    }

    mounted (): void{        
        this.getGiveaway()
        this.$socket.client.on('give-so-close', this.giveIsSoClose);
        this.$socket.client.on('give-ready', this.giveIsReady);
        this.$socket.client.on('give-crawled', this.giveIsCrawled);
    }

    beforeDestroy (): void {
        this.$socket.client.off('give-so-close', this.giveIsSoClose);
        this.$socket.client.off('give-ready', this.giveIsReady);
        this.$socket.client.off('give-crawled', this.giveIsCrawled);
    }

    
}
